<template>
  <div class="card p-1">
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">ค้นหาทรัพย์สิน</p>
      </div>
      <hr />
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>เลขทะเบียน:</label>
            <b-form-input placeholder="ค้นหา" type="text" class="d-inline-block" v-model="equipment_code" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>รหัสทรัพย์สิน:</label>
            <b-form-input placeholder="ค้นหา" type="text" class="d-inline-block" v-model="e_number" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>SERIAL NUMBER:</label>
            <b-form-input placeholder="ค้นหา" type="text" class="d-inline-block" v-model="serial_number" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>รายละเอียด:</label>
            <b-form-input placeholder="ค้นหา" type="text" class="d-inline-block" v-model="e_information" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>ปีงบประมาณ:</label>
            <b-form-input placeholder="ค้นหา" type="text" class="d-inline-block" v-model="budget_year" />
          </b-form-group>
        </b-col>
        <div class="row col-3 mb-1 mt-2">
          <div class="ml-1">
            <b-button variant="success" @click="searchTable"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cancel_searchTable"> ยกเลิก </b-button>
          </div>
        </div>
        <!-- <div class="row col-4">
          <div class="col-6">
            <label for="">สถานะ</label>
            <b-form-select v-model="status" :options="statusA" />
          </div>
          <div class="row">
            <div class="ml-1 mt-2">
              <b-button variant="success"> ค้นหา </b-button>
            </div>
            <div class="ml-1 mt-2">
              <b-button variant="danger"> ยกเลิก </b-button>
            </div>
          </div>
        </div> -->
      </b-row>
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}" theme="my-theme" :line-numbers="true">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span>{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'listmenu'">
            <span>
              <b-button variant="outline-warning" :to="{ name: 'inseakuruplun', params: { id: props.row } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'edit'">
            <span>
              <b-button variant="outline-warning" @click="editc(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'จำนวน'">
            <div class="text-right">{{ props.row.จำนวน }}</div>
          </span>
          <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <div v-if="props.row.status === 'รับเข้า'">
              <b-button variant="info">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'เบิก'">
              <b-button variant="warning">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'ยืม'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'ส่งคืน'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'คืน'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else="props.row.status">
              <b-button variant="danger">
                <!-- {{ props.row.status }} -->
                {{ props.row.reject_text }}
              </b-button>
            </div>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage1(value))" />
              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="total" :per-page="pageLength" first-number last-number align="right"
                prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="handlePagechange1($event)">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      budget_year: '',
      equipment_code: "",
      e_number: "",
      serial_number: "",
      e_information: "",
      status: null,
      statusA: [
        { value: null, text: "สถานะทั้งหมด" },
        { value: 1, text: "รับเข้า" },
        { value: 2, text: "ยืม" },
        { value: 3, text: "คืน" },
        { value: 4, text: "เบิก" },
        { value: 5, text: "รอเบิก" },
      ],
      currentPage: 1,
      pageLength: 100,
      total: "",
      show: false,
      dir: false,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "actiondate",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา วันที่-เดือน-ปี",
          },
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา เลขทะเบียน",
          },
        },
        {
          label: "รหัสทรัพย์สิน",
          field: "e_number",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา รหัสทรัพย์สิน",
          },
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา SERIAL NUMBER",
          },
        },
        {
          label: "รายละเอียด",
          field: "information",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา รายละเอียด",
          },
        },
        {
          label: "ปีงบประมาณ",
          field: "budget_year",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ปีงบประมาณ",
          },
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา หน่วยนับ",
          },
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice_vat",
          type: "number",
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ราคาต่อหน่วย",
          },
        },
        {
          label: "จำนวน",
          field: "amount",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา จำนวน",
          },
        },

        {
          label: "สถานที่",
          field: "place",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา สถานที่",
          },
        },
        {
          label: "สถานะ",
          field: "status",
          tdClass: "text-center",
          thClass: "text-center",
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา สถานะ",
          },
        },
        {
          label: "รายละเอียด",
          field: "listmenu",
          width: "7%",
        },
        {
          label: "แก้ไข",
          field: "edit",
          width: "7%",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    //this.checkLogout();
  },
  methods: {
    async editc(value) {
      console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}receiveAccess?equipment_code=${value.equipment_code}&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      this.total = res.data.message.total;
      let idx = Math.ceil(Number(this.total) / 100);
      let datai = [];
      for (let index = 1; index <= idx; index++) {
        const url_pdf = `${API}receiveAccess?_sort=-1&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, head);
        const { data } = res_pdfs.data.message;
        // console.log(data);
        datai = data;
      }

      console.log(datai);
      const ddd = datai.find((element) => element.refkey == value.refkey);
      console.log(ddd);

      this.$router.push({
        name: "in-inputkuruplun",
        params: {
          rowData: {
            id: ddd.id,
            e_name: ddd.e_name,
            e_id: ddd.e_id,
            e_gid: ddd.e_gid,
            budget_year: ddd.budget_year,
            information: ddd.information,
            unittype: ddd.unittype,
            unitprice: ddd.unitprice,
            unitprice_vat: ddd.unitprice_vat,
            admitdate: ddd.admitdate,
            equipment_code: ddd.equipment_code,
            amount: ddd.amount,
            work_gid: ddd.work_gid,
            evidence_type: ddd.evidence_type,
            response_person: ddd.response_person,
            important_number: ddd.important_number,
            work_gid: ddd.work_gid,
            date_raw: ddd.date_raw,
            refkey: ddd.refkey,
            e_number: value.e_number,
            edit: true,
          }, // or anything you want
        },
      });
    },
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },

    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    cancel_searchTable() {
      this.equipment_code = "";
      this.e_number = "";
      this.e_information = "";
      this.serial_number = "";
      this.rows = [];
    },
    searchTable() {
      if (this.equipment_code == "" && this.e_number == "" && this.e_information == "" && this.serial_number == "" && this.budget_year == "") {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "กรุณากรอกข้อมูลค้นหาวัสดุ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.get_data_table();
      }
    },
    handlePagechange1(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage1(event) {
      this.pageLength = event;
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}searchEuipment?equipment_code=${this.equipment_code}&e_number=${this.e_number}&serial_number=${this.serial_number}&information=${this.e_information}&budget_year=${this.budget_year}&_page=${this.currentPage}&_limit=${this.pageLength}`;
      console.log(url);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      this.rows = res.data.message.data.map((element) => {
        if (element.status === "ส่งคืน") {
          return {
            ...element,
            actiondate: `${parseInt(element.updatedAt.split("-")[2])} ${this.month[parseInt(element.updatedAt.split("-")[1])]
              } ${Number(element.updatedAt.split("-")[0]) + 543}`,
          };
        } else {
          return {
            ...element,
            actiondate: `${parseInt(element.actiondate.split("-")[2])} ${this.month[parseInt(element.actiondate.split("-")[1])]
              } ${Number(element.actiondate.split("-")[0]) + 543}`,
          };
        }
      });
      this.total = res.data.message.total;
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
